<template>
    <div class="login">
        <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            class="login-form"
        >
            <h3 class="title">{{ title }}</h3>
            <el-form-item prop="username">
                <el-input
                    v-model="loginForm.username"
                    type="text"
                    placeholder="账号"
                >
                    <svg-icon
                        slot="prefix"
                        icon-class="user"
                        class="el-input__icon input-icon"
                    />
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input
                    v-model="loginForm.password"
                    type="password"
                    :readonly="readonly"
                    @focus="readonly = false"
                    placeholder="密码"
                    @keyup.enter.native="handleLogin"
                >
                    <svg-icon
                        slot="prefix"
                        icon-class="password"
                        class="el-input__icon input-icon"
                    />
                </el-input>
            </el-form-item>
            <el-form-item prop="code">
                <el-input
                    v-model="loginForm.code"
                    auto-complete="off"
                    placeholder="验证码"
                    style="width: 63%"
                    @keyup.enter.native="handleLogin"
                >
                    <svg-icon
                        slot="prefix"
                        icon-class="validCode"
                        class="el-input__icon input-icon"
                    />
                </el-input>
                <div class="login-code">
                    <img :src="codeUrl" @click="getCode" alt="验证码" />
                </div>
            </el-form-item>
            <el-checkbox
                class="login-chebox"
                v-model="loginForm.rememberMe"
                style="margin-bottom: 25px"
                >记住密码</el-checkbox
            >
            <el-form-item style="width:100%;">
                <el-button
                    :loading="loading"
                    size="medium"
                    type="primary"
                    style="width:100%;"
                    @click.native.prevent="handleLogin"
                >
                    <span v-if="!loading">登 录</span>
                    <span v-else>登 录 中...</span>
                </el-button>
            </el-form-item>
            <!--  底部  -->
            <div class="el-login-footer">
                <span>Copyright © 2019-2020 DONGBEI All Rights Reserved.</span>
            </div>
            <!--验证码弹窗-->
            <el-dialog
                title="验证登录"
                :visible.sync="dialogVisible"
                width="360px"
                :close-on-click-modal="false"
            >
                <div class="code-tip">
                    由于您登录的账号需要权限确认，请输入系统管理员收到的短信验证码
                </div>
                <el-input
                    v-model="mobile"
                    style="width: 100%;margin-bottom: 20px"
                    placeholder="请输入手机号码"
                ></el-input>
                <div
                    style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 20px"
                >
                    <el-input
                        v-model="codeForm.code"
                        style="width: 200px"
                        placeholder="请输入短信验证码"
                    ></el-input>
                    <el-button
                        :disabled="codeCD > 0"
                        plain
                        @click="getNoteCode"
                        style="width: 112px"
                        >{{
                            codeCD > 0 ? codeCD + "s" : "发送验证码"
                        }}</el-button
                    >
                </div>
                <el-button
                    type="primary"
                    style="width: 100%;"
                    @click="noteCodeLogin"
                    >登 录</el-button
                >
            </el-dialog>
        </el-form>
    </div>
</template>

<script>
import {
    getCodeImg,
    loginInfo,
    smsSend,
    superManagementLogin,
    getInfo,
} from "@/api/login";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import setting from "@/settings";

export default {
    name: "Login",
    data() {
        return {
            title: setting.title,
            codeUrl: "",
            cookiePassword: "",
            loginForm: {
                username: "",
                password: "",
                rememberMe: false,
                code: "",
                uuid: "",
            },
            loginRules: {
                username: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "用户名不能为空",
                    },
                ],
                password: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "密码不能为空",
                    },
                ],
                code: [
                    {
                        required: true,
                        trigger: "change",
                        message: "验证码不能为空",
                    },
                ],
            },
            loading: false,
            redirect: undefined,
            dialogVisible: false,
            codeForm: {
                code: "",
            },
            codeCD: 0,
            mobile: "",
            readonly: false,
        };
    },
    watch: {
        $route: {
            handler: function(route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true,
        },
    },
    created() {
        this.getCode();
        this.getCookie();
    },
    methods: {
        //获取验证码
        getCode() {
            getCodeImg().then((res) => {
                if (res.code === 200) {
                    this.codeUrl = "data:image/gif;base64," + res.data.img;
                    this.loginForm.uuid = res.data.uuid;
                } else {
                    this.msgError("获取验证码失败");
                }
            });
        },
        getCookie() {
            const username = Cookies.get("username");
            const password = Cookies.get("password");
            const rememberMe = Cookies.get("rememberMe");
            this.loginForm = {
                username: username ? username : "",
                password: password ? decrypt(password) : "",
                rememberMe: rememberMe ? Boolean(rememberMe) : false,
            };
        },
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    if (this.loginForm.rememberMe) {
                        Cookies.set("username", this.loginForm.username, {
                            expires: 30,
                        });
                        Cookies.set(
                            "password",
                            encrypt(this.loginForm.password),
                            {
                                expires: 30,
                            }
                        );
                        Cookies.set("rememberMe", this.loginForm.rememberMe, {
                            expires: 30,
                        });
                    } else {
                        Cookies.remove("username");
                        Cookies.remove("password");
                        Cookies.remove("rememberMe");
                    }
                    loginInfo(this.loginForm).then((res) => {
                        this.loading = false;
                        if (res.code === 200) {
                            if (res.data.userType === "00") {
                                this.mobile = "";
                                this.codeForm.code = "";
                                this.readonly = true;
                                this.dialogVisible = true;
                            } else {
                                this.$store
                                    .dispatch("Login", this.loginForm)
                                    .then(() => {
                                        this.msgSuccess("登录成功");
                                        this.$router.push("/");
                                    })
                                    .catch((result) => {
                                        this.msgError(result.msg);
                                        this.getCode();
                                    });
                            }
                        } else {
                            this.msgError(res.msg);
                            this.getCode();
                        }
                    });
                }
            });
        },
        //获取手机号
        getNoteCode() {
            if (!this.mobile) return this.msgError("请输入手机号码");
            if (!/^1\d{10}$/.test(this.mobile))
                return this.msgError("请输入正确的手机号码");
            smsSend({
                userName: this.loginForm.username,
                mobile: this.mobile,
            }).then((res) => {
                if (res.code === 200) {
                    this.codeCD = 120;
                    let timer = setInterval(() => {
                        if (this.codeCD > 0) {
                            this.codeCD--;
                        } else {
                            clearInterval(timer);
                        }
                    }, 1000);
                    this.codeForm.createTime = res.data;
                    this.msgSuccess(res.msg);
                } else {
                    this.msgError(res.msg);
                }
            });
        },
        //发送验证码
        noteCodeLogin() {
            if (!this.codeForm.createTime) return this.msgError("请发送验证码");
            if (!this.codeForm.code) return this.msgError("请输入验证码");
            superManagementLogin({
                userName: this.loginForm.username,
                code: this.codeForm.code,
                timeStamp: this.codeForm.createTime,
            }).then((result) => {
                if (result.code === 200) {
                    this.$store
                        .dispatch("Login", this.loginForm)
                        .then(() => {
                            this.$message({
                                message: "登录成功",
                                type: "success",
                            });
                            this.$router.push("/");
                        })
                        .catch((res) => {
                            this.$message({
                                message: res.msg,
                                type: "error",
                            });
                            this.loading = false;
                            this.getCode();
                        });
                } else {
                    this.msgError(result.msg);
                }
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scope>
.login {
    position: relative;
    height: 100%;
    background-image: url("../assets/image/login-background.jpg");
    background-size: cover;
    overflow: hidden;
    .login-form {
        position: absolute;
        right: 13%;
        top: 20%;
        border-radius: 6px;
        background: #ffffff;
        width: 400px;
        padding: 25px 25px 5px 25px;
        box-shadow: 0 10px 60px 0 rgba(200, 212, 235, 0.31);
        .el-input {
            height: 38px;
            input {
                height: 38px;
                background: white !important;
            }
        }
        .input-icon {
            height: 39px;
            width: 14px;
            margin-left: 2px;
        }

        .el-input__inner {
            background-color: white !important;
            border-color: #dcdfe6 !important;
        }
        .el-checkbox__inner {
            background-color: white !important;
            border-color: #dcdfe6 !important;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #409eff !important;
            border-color: #409eff !important;
        }
    }
    .el-login-footer {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        bottom: -40%;
        color: #afafb8;
        font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: 14px;
        letter-spacing: 1px;
    }
    .title {
        margin: 0 auto 30px;
        text-align: center;
        color: #707070;
    }
    .login-code {
        width: 33%;
        height: 38px;
        float: right;
        img {
            cursor: pointer;
            vertical-align: middle;
        }
    }
    .code-tip {
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 40px;
    }
}
</style>
